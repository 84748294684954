@import "../config/imports";

/* Add here all your CSS customizations */
body {
    font-size: 16px;
    background-color: #FFF;

    &.logged-out {
        .visible-logged-in {
            display: none !important;
        }

        .visible-logged-in-admin {
            display: none !important;
        }
    }

    &.logged-in {
        .visible-logged-out {
            display: none !important;
        }

        &:not(.logged-in-admin) {
            .visible-logged-in-admin {
                display: none !important;
            }
        }
    }
}

.webshop-country-selector {
    &+.select2 {
        width: 200px !important;
    }
}
.log-out-icon{
    cursor:pointer;
}

#signup-country-code {
    &+.select2 {
        .selection {
            .select2-selection {
                border: 1px solid rgba(0, 0, 0, 0.09);
                box-shadow: none;
            }
        }
    }
}

.webshop-country-selector,
#signup-country-code,
#edit-profile-country-code {
    &+.select2 {
        .select2-selection {
            padding: 9px 24px 9px 12px;
            height: 40px;
            border: 1px solid #ccc;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

            .select2-selection__rendered {
                line-height: 1.42857143;
                padding: 0;
                font-size: 14px;
            }

            .select2-selection__arrow {
                height: 100%;
                position: absolute;
                bottom: 0;
                right: 12px;
                top: 0;
                width: 4px;
                font-size: 14px;
            }
        }
    }

    &+.select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 4px 4px 0;
        height: 0;
        left: 0;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
    }

    &+.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        transform: rotateX(180deg);
    }
}

select.is-invalid+.select2>.selection>span {
    border-color: #dc3545 !important;
}

.payment-icon {}

.btn.btn-primary {
    background-color: #004B8C;
}

.dropdown-menu.dropdown-menu-language {
    min-width: auto;
}

.form-group {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 30px;
    }

    .form-control {
        &.is-invalid {
            background-image: none;
        }
    }

    &.form-group-password {
        position: relative;

        input[type="password"] {
            padding-right: 40px;
        }

        .show-password-icon {
            width: 24px;
            height: 18px;
            visibility: visible;
            position: absolute;
            top: 38px;
            right: 10px;
            cursor: pointer;
        }
    }

    &>label {
        font-size: 16px;
        color: black;
        margin-bottom: 5px;
    }

    &>header {
        &>label {
            font-size: 16px;
            color: black;
            margin-bottom: 5px;
        }

        &>a {
            font-size: 14px;
            color: #5A5A5A;
        }
    }
}

.alert {

    z-index: 9999;
    border: none;
    color: white;
    position: fixed;
    left: 100px;
    bottom: 0;
    width: 290px;

    &.alert-danger {
        background-color: #E74C3C;
    }

    &.alert-success {
        background-color: #2ECC71;
        display: none;
    }

    &.show {
        display: block;
    }

    &.alert-danger {
        z-index: 9999;
        border: none;
        background-color: #E74C3C;
        color: white;
        position: fixed;
        left: 100px;
        bottom: 0;
        width: 290px;

    }
}

.underline {
    text-decoration: underline;
}

a {
    color: #004B8C;

    &:hover {
        color: #004B8C;
    }

    &:active {
        color: #004B8C;
    }

    &:focus {
        color: #004B8C;
    }
}

header.opten-page-header {
    background-color: #004B8C;
    padding: 100px;
    font-size: 32px;
    color: white;
    position: relative;

    @media (max-width: 768px) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media (max-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    p{
        color: white;
        padding-top: 20px;
    }

    ul.nav.nav-tabs {
        position: absolute;
        bottom: 0;
        border: none;

        li.nav-item {
            margin-bottom: 0;

            >a {
                background: transparent;
                border: none;
                text-transform: uppercase;
                color: #89B8E1;
                padding-left: 0;
                padding-right: 0;
                margin-right: 40px;
                border-bottom: solid 2px transparent;

                &.active {
                    color: white;
                    position: relative;
                    border-bottom: solid 2px #58C3FF;
                }
            }
        }
    }
}

[data-dir] {
    position: relative;
    cursor: pointer;

        &:before {
            position: absolute;
            right: 0;
            top: -4px;
            font-family: "Font Awesome 5 Free";
            content: "\f0d8";
        }

    &:after {
        position: absolute;
        right: 0;
        top: 4px;
        font-family: "Font Awesome 5 Free";
        content: "\f0d7";
    }
}

[data-dir="asc"] {
    &:before {
        content: "";
    }
}

[data-dir="desc"] {
    &:after {
        content: "";
    }
}

.danger {
    color: $color-danger;
    border-color: $color-danger;
}
.success {
    color: $color-success;
    border-color: $color-success;
}
.warning {
    color: $color-warning;
    border-color: $color-warning;
}